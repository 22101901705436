import styles from "./navigation.module.css";
import { useSelector } from "react-redux";
import { getSearchSharedNumOfProjects } from "@/redux/search/shared/selectors";
import Logo from "@/svg/logo.svg";
import LeftMenu from "@/components/LeftMenu";

const NavigationLeftHomepage = () => {
  const numOfProjects = useSelector(getSearchSharedNumOfProjects);

  return (
    <div className={styles.container}>
      <LeftMenu className={styles.hamburger} />
      <div className={styles.count}>
        <a href={"/"}>
          <span>Hľadajte medzi</span>
          <span className={styles.span}> {numOfProjects} </span>
          <span>novými bytmi a domami!</span>
        </a>
      </div>
      <div className={styles.logo}>
        <a href={"/"}>
          <Logo />
        </a>
      </div>
    </div>
  );
};

export default NavigationLeftHomepage;
