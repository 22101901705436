import { FunctionComponent } from "react";
// import CardProject from "@/components/Card/Project";
import Page from "@/components/Page";
import NavigationPresetHomepage from "@/components/Navigation/Preset/Homepage";
import SearchBar from "@/components/SearchBar";
import Meta from "@/components/Meta";
import { GetNumOfProjects } from "@/utils/_app/getNumOfProjects";
import { useDispatch, useSelector } from "react-redux";
import { setSearchSharedNumOfProjects } from "@/redux/search/shared";
import { SITE_NAME } from "@/data/constants";
// import { TSearchList } from "@/redux/search/list/types";
// import { setSearchListBanners } from "@/redux/search/list";
// import BannerInline from "@/components/Banner/Inline";
import { getSearchSharedLoading } from "@/redux/search/shared/selectors";
import Loading from "@/components/Loading";
// import { ProjectTips } from "@/api/fetchHydratedProjectTips";
// import objectLocationToArray from "@/utils/object/objectLocationToArray";
// import styles from "./index.module.css";
// import { useWindowSize } from "usehooks-ts";
// import { PopularLocations } from "@/api/fetchHydratedPopularLocations";
import { GetServerSideProps } from "next";
import getNumOfProjects from "@/utils/_app/getNumOfProjects";
// import { getPopularLocations } from "@/utils/_app/getPopularLocations";
// import fetchRandomBanners from "@/api/fetchRandomBanners";
// import fetchHydratedProjectTips from "@/api/fetchHydratedProjectTips";
import { popularLocations } from "@/data/popularLocations";
import { PopularLocations } from "@/types/api/complex";

type Props = GetNumOfProjects & {
  locations: PopularLocations;
  // banners: TSearchList["banners"];
  //projectTips: ProjectTips;
};
import SlickCarousel from "@/components/SlickCarousel";

const Index: FunctionComponent<Props> = ({
  numOfProjects,
  locations,
  // banners,
  // projectTips,
}) => {
  const dispatch = useDispatch();
  dispatch(setSearchSharedNumOfProjects(numOfProjects));
  // dispatch(setSearchListBanners(banners));

  // const { width } = useWindowSize();
  const loading = useSelector(getSearchSharedLoading);

  return (
    <Page navigation={NavigationPresetHomepage}>
      <Meta
        type={"homepage"}
        title={SITE_NAME}
        description={
          "Jedinečný realitný server, ktorý vyhľadáva v ponukách novostavieb. Vyhľadávame v najväčšom počte ponúk nových bytov a domov v celej SR."
        }
      />

      {loading && <Loading />}

      <SearchBar />

      {/* {projectTips?.length ? (
        <Carousel
          title={"Projektové tipy"}
          items={projectTips.map((v) => ({
            image: {
              full:
                v.estates.length && v.estates[0]?.thumbnailUrl
                  ? v.estates[0].thumbnailUrl
                  : "/images/image_not_found.png",
              alt: `${v.project?.name} - ${v.project?.developer}`,
            },
            address: v.estates.length && v.estates[0]?.locality,
            breadcrumbs: objectLocationToArray(v.filter.address),
            alt: v.project?.name,
            title: v.project?.name,
            company: v.project?.developer,
            price: v.project?.price,
            count: v.project?.count,
            ppc: v.project?.ppc,
          }))}
          component={CardProject}
        />
      ) : (
        ""
      )} */}

      {/* {width >= 1000 && banners?.top && banners.top.length ? (
        <BannerInline className={styles.banner} data={banners.top[0]} />
      ) : (
        ""
      )} */}

      {locations?.length ? (
        <SlickCarousel title={"Populárne lokality"} items={locations} />
      ) : (
        ""
      )}
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const numOfProjects = (await getNumOfProjects()) || null;
  // const locations = (await getPopularLocations()) || null;
  // const topBanners = (await fetchRandomBanners({}, "top", 1)) || null;
  // const projectTips = (await fetchHydratedProjectTips()) || null;

  // const banners = {
  //   top: topBanners,
  //   inline: null,
  //   side: null,
  //   side_mobile: null,
  // };

  return {
    props: {
      numOfProjects,
      locations: popularLocations,
      // banners,
      // projectTips,
    },
  };
};

export default Index;
