import { PopularLocations } from "@/types/api/complex";
import { FunctionComponent, createRef } from "react";
import Slider from "react-slick";
import CardLocation from "../Card/Location";
import styles from "./carousel.module.css";
import Arrow from "@/svg/arrow.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {
  title: string;
  items: PopularLocations;
};

const SlickCarousel: FunctionComponent<Props> = ({ title, items }) => {
  const sliderRef = createRef<Slider>();

  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.top}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.arrows}>
          <div className={styles.arrow} data-side="left" onClick={handlePrev}>
            <Arrow />
          </div>
          <div className={styles.arrow} data-side="right" onClick={handleNext}>
            <Arrow />
          </div>
        </div>
      </div>
      <Slider {...settings} ref={sliderRef}>
        {items.map((item) => (
          <div key={item.image} className="pr-2">
            <CardLocation
              title={item.title}
              count={item.count}
              image={item.image}
              address={item.address}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default SlickCarousel;
