import { PopularLocations } from "@/types/api/complex";
import { EstateTypeKeys, OfferTypeKeys } from "@/types/api/derived";
import {
  ESTATE_TYPE_MAP_KEYS_URLS,
  OFFER_TYPE_MAP_KEYS_URLS,
} from "@/types/api/maps";
import urlFromQuery from "@/utils/url/urlFromQuery";
import { FunctionComponent } from "react";
import { DEFAULT_PROPERTY_FILTER } from "@/data/constants";
import styles from "./location.module.css";
import Image from "next/legacy/image";

type Props = PopularLocations[number];

const CardLocation: FunctionComponent<Props> = ({
  title,
  count,
  image,
  address,
}) => {
  const all = [
    address.country,
    address.region,
    address.city,
    address.street,
  ].filter((v) => !!v) as string[];
  const location = all[all.length - 1].toLowerCase();
  const query = all.join("~");

  const url =
    "/novostavby/" +
    urlFromQuery({
      offerType:
        OFFER_TYPE_MAP_KEYS_URLS[
          (DEFAULT_PROPERTY_FILTER.offerType as OfferTypeKeys[])[0]
        ],
      type: (DEFAULT_PROPERTY_FILTER.type as EstateTypeKeys[])
        .map((v) => ESTATE_TYPE_MAP_KEYS_URLS[v])
        .join("_"),
      location,
      query,
    });

  return (
    <a href={url} className={styles.location}>
      <Image
        className={styles.image}
        src={image || ""}
        alt={title}
        layout="fill"
      />
      {count ? (
        <div className={styles.count}>{count} jednotiek</div>
      ) : (
        <div></div>
      )}

      <h2 className={styles.title}>{title}</h2>
    </a>
  );
};

export default CardLocation;
