import { Maybe } from "@/types/general";
import { SearchQuery } from "@/types/api/complex";
import mapObjectWithout from "@/utils/object/mapObjectWithout";

const urlFromQuery = (query: Maybe<SearchQuery>) => {
  if (query) {
    const offerType = query.offerType;
    const type = query.type;
    const location = query.location || "";
    const mappedQuery = mapObjectWithout(query, [
      "offerType",
      "type",
      "location",
    ]);

    const urlQuery: string = new URLSearchParams(
      mappedQuery as Record<string, string>,
    ).toString();

    return `${offerType}/${type}/${location}?${urlQuery}`;
  }

  return "/";
};

export default urlFromQuery;
