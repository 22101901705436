import { FunctionComponent } from "react";
import NavigationLeftHomepage from "../Left/Homepage";
import NavigationRightDefault from "../Right/Default";
import Navigation from "../index";

const NavigationPresetHomepage: FunctionComponent = () => (
  <Navigation left={NavigationLeftHomepage} right={NavigationRightDefault} />
);

export default NavigationPresetHomepage;
